import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DEL_MODAL } from 'constants';
import CancelLicense from './components/CancelLicense';
import DeleteApplication from './components/DeleteApplication';
import CreateWorkspace from './components/CreateWorkspace';
import ModifyWorkspace from './components/ModifyWorkspace';
import BlockWorkspace from './components/BlockWorkspace';
import ManageUserRoles from './components/ManageUserRoles';
import ManageUserGroups from './components/ManageUserGroups';
import DeleteWorkspace from './components/DeleteWorkspace';
import CreateRole from './components/CreateRole';
import ModifyRole from './components/ModifyRole';
import DeleteRole from './components/DeleteRole';
import BlockUser from './components/BlockUser';
import BlockEnvUser from './components/BlockEnvUser';
import UnblockEnvUser from './components/UnblockEnvUser';
import BlockApiKey from './components/BlockApiKey';
import RemoveUserFromWorkspace from './components/RemoveUserFromWorkspace';
import ChangeGroupOwnership from './components/ChangeGroupOwnership';
import ChangeWorkspaceOwnership from './components/ChangeWorkspaceOwnership';
import ChangeWorkspaceOwnershipNoRequest from './components/ChangeWorkspaceOwnershipNoRequest';
import CreateGroup from './components/CreateGroup';
import DeleteGroup from './components/DeleteGroup';
import ModifyGroup from './components/ModifyGroup';
import ModifyGroupUsers from './components/ModifyGroupUsers';
import ModifyGroupApiKeys from './components/ModifyGroupApiKeys';
import ModifyRoleUsers from './components/ModifyRoleUsers';
import CreateApiKey from './components/CreateApiKey';
import ModifyApiKey from './components/ModifyApiKey';
import ModifyWebhook from './components/ModifyWebhook';
import CreateWebhook from './components/CreateWebhook';
import DeleteWebhook from './components/DeleteWebhook';
import DeleteApiKey from './components/DeleteApiKey';
import DeleteInvite from './components/DeleteInvite';
import LeaveWorkspace from './components/LeaveWorkspace';
import InviteToWorkspace from './components/InviteToWorkspace';
import TwoFactorAuth from './components/TwoFactorAuth';
import OffTwoFactorAuth from './components/OffTwoFactorAuth';
import ChangeLicenseRPS from './components/ChangeLicenseRPS';
import ChangeLicenseStateChanges from './components/ChangeLicenseStateChanges';
import AddLicense from './components/AddLicense';
import ModifyWorkspaceOwnership from './components/ModifyWorkspaceOwnership';
import './Modal.scss';

const __MODALS__ = {
  DeleteApplication,
  TwoFactorAuth,
  OffTwoFactorAuth,

  CreateWorkspace,
  ModifyWorkspace,
  DeleteWorkspace,
  LeaveWorkspace,
  BlockWorkspace,
  InviteToWorkspace,
  DeleteInvite,

  CreateGroup,
  DeleteGroup,
  ModifyGroup,
  ModifyGroupUsers,
  ModifyGroupApiKeys,

  CreateRole,
  ModifyRole,
  DeleteRole,
  ModifyRoleUsers,

  CreateApiKey,
  DeleteApiKey,
  ModifyApiKey,

  ManageUserRoles,
  ManageUserGroups,

  BlockUser,
  BlockApiKey,
  RemoveUserFromWorkspace,

  ChangeGroupOwnership,
  ChangeWorkspaceOwnership,
  ChangeWorkspaceOwnershipNoRequest,

  AddLicense,
  CancelLicense,

  ChangeLicenseRPS,
  ChangeLicenseStateChanges,

  ModifyWebhook,
  CreateWebhook,
  DeleteWebhook,

  ModifyWorkspaceOwnership,

  BlockEnvUser,
  UnblockEnvUser
};

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visibility: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ visibility: true }), 10);
  }

  onClose() {
    this.setState({ visibility: false });
    setTimeout(() => this.props.dispatch({ type: DEL_MODAL }), 300);
  }

  render() {
    const { name, data, formStatus, dispatch, users, auth, eventView, callbacks = {} } = this.props;

    const { visibility } = this.state;
    const Component = __MODALS__[name];

    return (
      <Component
        name={name}
        data={data}
        visibility={visibility}
        formStatus={formStatus}
        dispatch={dispatch}
        users={users}
        auth={auth}
        eventView={eventView}
        onClose={this.onClose.bind(this)}
        callbacks={callbacks}
      />
    );
  }
}

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object,
  callbacks: PropTypes.object,
  users: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  formStatus: PropTypes.object,
  eventView: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mapStateToProps = state => ({
  formStatus: state.formStatus,
  users: state.users,
  auth: state.auth,
  eventView: state.eventView
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
