import React from 'react';
import { ProgressBar } from 'mw-style-react';
import './Loader.scss';

function Loader() {
  return (
    <div styleName="Loader">
      <ProgressBar />
    </div>
  );
}

export default Loader;
