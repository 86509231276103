import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Modal, ModalButtons, Button, TextField, Checkbox, Label, Icon } from 'mw-style-react';
import { CREATE_API_KEY, GET_SCOPES, accountComponents } from 'constants';
import connect from 'react-redux/es/connect/connect';
import cn from 'classnames';
import AppUtils from '../../../../utils/utils';
import TextButton from '../../../TextButton';
import mes from './intl';
import sModal from '../../Modal.scss'; // eslint-disable-line no-unused-vars
import ImageUploaderEditable from '../../../ImageUplaoderEditable/ImageUploaderEditable';

class CreateApiKey extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      name: '',
      photo: '',
      webhookName: '',
      checkBoxes: {},
      error: false,
      webhookError: false,
      activeScopesGroups: {},
      color: undefined
    };

    this.getScopes();
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  getScopes() {
    const { dispatch } = this.props;

    dispatch({
      type: GET_SCOPES.REQUEST,
      payload: {
        callback: (res, data) => {
          if (res === 'success' && data && data.length) {
            const uniqueData = data.reduce((accumulator, item) => {
              const itemData = item.type;
              if (itemData) {
                accumulator[itemData] = true;
              }
              return accumulator;
            }, {});

            this.setState({ activeScopesGroups: uniqueData });
          }
        }
      }
    });
  }

  handleSubmit() {
    const { dispatch, onClose, data } = this.props;
    const { ext_id: workspaceId } = data || {};
    const { name, webhookName, checkBoxes, photo = '', color } = this.state;

    const scps = [];

    Object.keys(checkBoxes).forEach(c => {
      if (checkBoxes[c]) scps.push({ type: `scopes.${c.split(':')[0]}`, id: c.split(':')[1] });
    });

    if (
      !name.length ||
      (!!webhookName && !AppUtils.validateUrl(webhookName)) ||
      !AppUtils.validateName(name)
    ) {
      this.setState({
        error: !name.length || !AppUtils.validateName(name),
        webhookError: !!webhookName && !AppUtils.validateUrl(webhookName)
      });
      return;
    }

    this.setState({
      isSubmit: true
    });

    dispatch({
      type: CREATE_API_KEY.REQUEST,
      payload: {
        params: { workspaceId },
        body: {
          name,
          url: webhookName || undefined,
          scopes: scps,
          photo: photo.length ? photo : undefined,
          color: color || undefined
        },
        callback: result => {
          if (result === 'error') {
            this.setState({
              isSubmit: false
            });
          } else {
            onClose();
          }
        }
      }
    });
  }

  handleOnChangeName(e) {
    const { value } = e;
    this.setState({
      name: value,
      error: !AppUtils.validateName(value)
    });
  }

  handleOnChangeWebhookName(e) {
    const { value } = e;
    this.setState({
      webhookName: value,
      webhookError: false
    });
  }

  handleOnChangeCheckbox(id) {
    this.setState(prev => ({
      ...prev,
      checkBoxes: {
        ...prev.checkBoxes,
        [id]: !prev.checkBoxes[id]
      }
    }));
  }

  cutScope(text) {
    return text.split('.')[1];
  }

  calculateScopes() {
    const { apiKeys } = this.props;

    const { scopes } = apiKeys || {};

    const collections = (scopes || []).reduce(
      (collections, data) => {
        if (accountComponents[data.type] === 'Account') {
          collections.accountScopes.push(data);
        } else if (accountComponents[data.type] === 'Simulator') {
          collections.controlScopes.push(data);
        } else if (accountComponents[data.type] === 'Corezoid') {
          collections.corezoidScopes.push(data);
        }
        return collections;
      },
      {
        accountScopes: [],
        controlScopes: [],
        corezoidScopes: []
      }
    );

    return collections;
  }

  handleToggleGroup(group) {
    this.setState(prev => ({
      ...prev,
      activeScopesGroups: {
        ...prev.activeScopesGroups,
        [group]: !prev.activeScopesGroups[group]
      }
    }));
  }

  managePicture(data) {
    const { pictureUrl, color } = data || {};
    this.setState({
      photo: pictureUrl,
      color
    });
  }

  render() {
    const { visibility, onClose, apiKeys, dispatch } = this.props;

    const { scopes } = apiKeys || {};

    const {
      name,
      webhookName,
      isSubmit,
      error,
      webhookError,
      checkBoxes = {},
      photo,
      activeScopesGroups,
      color
    } = this.state;

    const { controlScopes, accountScopes, corezoidScopes } = this.calculateScopes();

    const varMapping = {
      'scopes.sa': accountScopes,
      'scopes.control': controlScopes,
      'scopes.corezoid': corezoidScopes
    };

    const anyTrue = Object.keys(checkBoxes).some(k => checkBoxes[k]);
    const scopesGroups = Object.keys(activeScopesGroups) || [];

    return (
      <Modal visibility={visibility} onClose={onClose} styleName="sModal.modal__workspace">
        <div styleName="sModal.modal__header">
          <div styleName="sModal.modal__header__close">
            <TextButton title="Cancel" onClick={onClose} />
          </div>
          <div styleName="sModal.modal__header__title">{this.i(mes.newApiKey)}</div>
        </div>
        <div styleName="sModal.modal__body" style={{ paddingTop: '20px', overflow: 'hidden' }}>
          <div style={{ overflowY: 'auto', overflowX: 'hidden', marginBottom: '20px' }}>
            <div styleName="modal__columns">
              <div styleName="modal__upload-image">
                <div style={{ marginBottom: 8 }} styleName="sModal.modal__input__label">
                  Image
                </div>
                <ImageUploaderEditable
                  size="xxlarge"
                  colorFilled={true}
                  iconLabel="Add image"
                  menuItemsLabels={{
                    add: 'Add image',
                    clear: 'Clear image'
                  }}
                  handleChange={this.managePicture.bind(this)}
                  pictureUrl={photo}
                  colors={[
                    { type: 'actor', color },
                    { type: 'form', color: '#BAD5F8' }
                  ]}
                  dispatch={dispatch}
                  showEditIcon
                />
                <div styleName="modal__upload-image-helper">PNG, JPG up to 1 MB</div>
              </div>
              <div styleName="modal__columns-inputs">
                <div>
                  <div styleName="sModal.modal__input__label">{this.i(mes.apiKeyNameLabel1)}</div>
                  <TextField
                    styleName="sModal.modal__input"
                    value={name}
                    onChange={this.handleOnChangeName.bind(this)}
                    placeholder={this.i(mes.apiKeyNamePlaceholder)}
                    length={255}
                    bordered
                    error={error}
                    id="name"
                    visibility={isSubmit ? 'disabled' : 'visible'}
                  />
                </div>
                <div>
                  <div styleName="sModal.modal__input__label">
                    {this.i(mes.apiKeyWebhookNameLabel1)}{' '}
                    <span styleName="sModal.modal__input__label__description">
                      {this.i(mes.apiKeyWebhookNameLabel2)}
                    </span>
                  </div>
                  <TextField
                    styleName="sModal.modal__input"
                    value={webhookName}
                    onChange={this.handleOnChangeWebhookName.bind(this)}
                    placeholder={this.i(mes.apiKeyWebhookNamePlaceholder)}
                    length={255}
                    autoFocus
                    bordered
                    error={webhookError}
                    id="webhookName"
                    visibility={isSubmit ? 'disabled' : 'visible'}
                  />
                </div>
              </div>
            </div>
            <div>
              <div styleName="sModal.modal__input__label">
                {this.i(mes.apiKeyScopesLabel1)}{' '}
                <span styleName="sModal.modal__input__label__description">
                  {this.i(mes.apiKeyScopesLabel2)}
                </span>
              </div>
              <div styleName="sModal.modal__checkboxGroup" style={{ margin: 0 }}>
                <ul>
                  {scopesGroups &&
                    scopesGroups.map(el => (
                      <li key={el} styleName={cn('sModal.modal__checkboxGroup__category')}>
                        <div style={{ width: '100%' }}>
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() => this.handleToggleGroup(el)}
                            onKeyPress={() => this.handleToggleGroup(el)}
                            styleName="sModal.modal__checkboxGroup__title"
                          >
                            <Icon
                              styleName={cn('sModal.modal__checkboxGroup__icon', {
                                'sModal.modal__checkboxGroup__rotatedArrow': !activeScopesGroups[el]
                              })}
                              type="dropdown"
                              color="#393f48"
                              size="micro"
                            />
                            {accountComponents[el] || el}
                          </div>
                          {scopes && scopes.length ? (
                            <ul
                              styleName={cn('sModal.modal__checkboxGroup__permList', {
                                'sModal.modal__checkboxGroup__hidden': !activeScopesGroups[el]
                              })}
                            >
                              {varMapping[el] &&
                                varMapping[el].map(p => (
                                  <li key={`${this.cutScope(p.type)}:${p.id}`}>
                                    <Checkbox
                                      value={checkBoxes[`${this.cutScope(p.type)}:${p.id}`]}
                                      onChange={() =>
                                        this.handleOnChangeCheckbox(
                                          `${this.cutScope(p.type)}:${p.id}`
                                        )
                                      }
                                      styleName="sModal.modal__checkboxGroup__checkbox"
                                    />
                                    <div styleName="sModal.modal__input__label__ellipsis">
                                      <Label fontWeight="semibold" value={p.id} />
                                      <br />
                                      <span styleName="sModal.modal__input__label__description">
                                        {p.name}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          ) : null}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <ModalButtons styleName="sModal.modal__buttons">
            <Button
              label={this.i(mes.btnCreateApiKey)}
              size="medium"
              onClick={this.handleSubmit.bind(this)}
              visibility={isSubmit || !anyTrue || this.state.error ? 'disabled' : 'visible'}
              disabled={isSubmit || !anyTrue || this.state.error}
              styleName="sModal.modal__btn sModal.wide"
            />
          </ModalButtons>
        </div>
      </Modal>
    );
  }
}

CreateApiKey.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  apiKeys: PropTypes.object
};

CreateApiKey.contextTypes = {
  intl: intlShape
};

const mapStateToProps = ({ apiKeys }) => ({
  apiKeys
});

export default injectIntl(connect(mapStateToProps)(CreateApiKey));
