import React from 'react';
import Client from 'mw-fetch';
import { Utils } from 'mw-style-react';
import ReactDOM from 'react-dom';
import { CONFIG, SET_LOCATION, SHOW_NOTIFY, NOTIFY_LEVEL } from 'constants';
import createStore from './store/createStore';
import AppContainer from './containers/AppContainer';
import { setHistory } from './store/history';

// scriptInjector.js

function injectGTM1() {
  const scriptContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W9W4KKG');
  `;

  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptContent;
  document.head.appendChild(scriptElement);
}

function injectGTM2() {
  const scriptContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MM8Q82MD');
  `;

  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptContent;
  document.head.appendChild(scriptElement);
}

// ========================================================
// Язык локализации
// ========================================================
let locale = navigator.language || navigator.systemLanguage || navigator.userLanguage;
if (['en'].indexOf(locale) === -1) {
  locale = 'en';
}

// ========================================================
// Инициализация Store
// ========================================================
const store = createStore();

// ========================================================
// Load Config
// ========================================================
async function loadConfig() {
  try {
    const client = new Client({
      credentials: 'include',
      mode: 'no-cors'
    });
    return await client.get('/face/api/1/auth/conf');
  } catch (error) {
    const label = 'Failed to load config';
    store.dispatch({ type: CONFIG.ERROR });
    store.dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: Utils.random().toString(),
        type: NOTIFY_LEVEL.ERROR,
        label: `${label}`
      }
    });
    throw error;
  }
}

// ========================================================
// Настройка рендера
// ========================================================
const MOUNT_NODE = document.getElementById('root');

const render = async messages => {
  // Загрузить конфиг и установить baseURL
  const res = await loadConfig();
  const payload = { ...res.data };
  store.dispatch({ type: CONFIG.SUCCESS, payload });
  const baseURL = (res.data.paths && res.data.paths.base_path) || '';
  const history = setHistory(baseURL);
  store.unsubscribeHistory = history.listen(({ location }) => {
    store.dispatch({ type: SET_LOCATION, payload: location.pathname });
  });

  if (location && location.hostname === 'account.corezoid.com') {
    injectGTM1();
    injectGTM2();
  }

  if (!window.Intl) {
    require.ensure(
      [],
      require => {
        require('intl');
        require('intl/locale-data/jsonp/en');
        ReactDOM.render(
          <AppContainer store={store} messages={messages} locale={locale} history={history} />,
          MOUNT_NODE
        );
      },
      'intl'
    );
  } else {
    ReactDOM.render(
      <AppContainer store={store} messages={messages} locale={locale} history={history} />,
      MOUNT_NODE
    );
  }
};

// ========================================================
// Динамическая загрузка файла локализации
// ========================================================
import(`../messages/${locale}.json`).then(messages => {
  // ========================================================
  // Запуск приложения
  // ========================================================
  render(messages);
});
