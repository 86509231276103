/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './TagsInput.scss';
import { DOMAIN_NANE_REGEXP } from '../../constants';

function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
const defaultSeparators = ['Enter', 'Spacebar', ' ', ',', 'Tab', ';'];
const VIEWS = {
  INLINE: 'inline',
  BLOCK: 'block'
};

function TagsInput(
  {
    name,
    placeHolder,
    value,
    onChange,
    onBlur,
    separators,
    disableBackspaceRemove,
    onExisting,
    onRemoved,
    disabled,
    isEditOnRemove,
    beforeAddValidate,
    onKeyUp,
    onSearch,
    addOnBlur = true,
    loading,
    view = VIEWS.INLINE
  },
  ref
) {
  const [tags, setTags] = useState(value || []);
  const containerRef = useRef(null);

  useDidUpdateEffect(() => {
    if (onChange) {
      onChange(tags);
    }
    if (containerRef && containerRef.current) {
      const container = containerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [tags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value]);

  const handleOnKeyUp = (e, add) => {
    e.stopPropagation();
    const text = e.target.value;

    if (!text && !disableBackspaceRemove && tags.length && e.key === 'Backspace') {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : '';
      setTags([...tags.slice(0, -1)]);
    }

    if (text && ((separators || defaultSeparators).includes(e.key) || add)) {
      e.preventDefault();
      if (beforeAddValidate && !beforeAddValidate(text, tags)) return;

      if (tags.map(el => el.name).includes(text)) {
        if (onExisting) {
          onExisting(text);
        }

        return;
      }
      const isValid = new RegExp(DOMAIN_NANE_REGEXP).test(text);

      if (isValid) {
        setTags([...tags, { name: text, id: text }]);
        e.target.value = '';
      }
    }
  };

  const onTagRemove = item => {
    setTags(tags.filter(tag => tag.id !== item.id));
    if (onRemoved) {
      onRemoved(item);
    }
  };

  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
    handleOnKeyUp(e, addOnBlur);
  };

  return (
    <div
      aria-labelledby={name}
      styleName={cn('rti--container', {
        'rti--container--disabled': disabled,
        'rti--container--loading': loading,
        'rti--container--block': view === VIEWS.BLOCK
      })}
    >
      {tags && tags.length ? (
        <Tags view={view} ref={containerRef}>
          {tags.map(tag => (
            <Tag key={tag.id} text={tag} remove={onTagRemove} disabled={disabled} />
          ))}
        </Tags>
      ) : null}

      <input
        ref={ref}
        styleName={cn('rti--input', {
          'rti--input--inline': view === VIEWS.INLINE,
          'rti--input--block': view === VIEWS.BLOCK
        })}
        type="text"
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onChange={onSearch}
        onBlur={handleBlur}
        disabled={disabled}
        onKeyUp={onKeyUp}
        spellCheck={false}
        autoComplete="off"
      />
    </div>
  );
}

function Tag({ text, remove, disabled }) {
  const handleOnRemove = e => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <span
      styleName={cn('rti--tag', {
        'rti--tag--disabled': disabled
      })}
    >
      <span>{text.name}</span>
      {!disabled && (
        <button type="button" onClick={handleOnRemove} aria-label={`Remove ${text.name}`}>
          &#10005;
        </button>
      )}
    </span>
  );
}

function TagsContainer({ view, children }, ref) {
  if (view === VIEWS.BLOCK) {
    return (
      <div ref={ref} styleName="rti--tags-container">
        {children}
      </div>
    );
  }
  if (view === VIEWS.INLINE) return children;
  return children;
}

const Tags = React.forwardRef(TagsContainer);

export default React.forwardRef(TagsInput);
